// @flow
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as form } from "redux-form";
import type { History } from "@types/history";

import { RouterState } from "shared/constants/flowTypes";
import app from "./app/app";
import type { State as AppState } from "./app";
import auth from "./auth";
import type { State as AuthState } from "./auth";
import certifications from "./certifications/certifications";
import type { State as CertificationsState } from "./certifications/certifications";
import connect from "./connect";
import type { State as ConnectState } from "./connect";
import course from "./course/course";
import type { State as CourseState } from "./course/course";
import courseItems from "./courseItems/courseItems";
import type { State as CourseItemsState } from "./courseItems/courseItems";
import courseOccurrences from "./courseOccurrences/courseOccurrences";
import type { State as CourseOccurrencesState } from "./courseOccurrences/courseOccurrences";
import courseInformationDialog from "./courseInformationDialog/courseInformationDialog";
import type { State as CourseInformationDialogState } from "./courseInformationDialog/courseInformationDialog";
import courseRegistrationDialog from "./courseRegistrationDialog/courseRegistrationDialog";
import type { State as CourseRegistrationDialogState } from "./courseRegistrationDialog/courseRegistrationDialog";
import createCourse from "./createCourse/createCourse";
import type { State as CreateCourseState } from "./createCourse/createCourse";
import curricula from "./curricula/curricula";
import type { State as CurriculaState } from "./curricula/curricula";
import editCourse from "./editCourse/editCourse";
import type { State as EditCourseState } from "./editCourse/editCourse";
import emailCourse from "./emailCourse/emailCourse";
import type { State as EmailCourseState } from "./emailCourse/emailCourse";
import forms from "./forms";
import type { State as FormsState } from "./forms";
import images from "./images/images";
import type { State as ImagesState } from "./images/images";
import instructor from "./instructor/instructor";
import type { State as InstructorState } from "./instructor/instructor";
import instructorAccount from "./instructorAccount/instructorAccount";
import type { State as InstructorAccountState } from "./instructorAccount/instructorAccount";
import instructorAgreements from "./instructorAgreements/instructorAgreementsReducer";
import type { State as InstructorAgreementsState } from "./instructorAgreements/instructorAgreementsReducer";
import instructorCourses from "./instructorCourses/instructorCourses";
import type { State as InstructorCoursesState } from "./instructorCourses/instructorCourses";
import instructorCourseOccurrences from "./instructorCourseOccurrences/instructorCourseOccurrences";
import type { State as InstructorCourseOccurrencesState } from "./instructorCourseOccurrences/instructorCourseOccurrences";
import instructorResources from "./instructorResources/instructorResources";
import type { State as InstructorResourcesState } from "./instructorResources/instructorResources";
import instructorReviews from "./instructorReviews/instructorReviews";
import type { State as InstructorReviewsState } from "./instructorReviews/instructorReviews";
import manageCourse from "./manageCourse/manageCourse";
import type { State as ManageCourseState } from "./manageCourse/manageCourse";
import manageCourses from "./manageCourses/manageCourses";
import type { State as ManageCoursesState } from "./manageCourses/manageCourses";
import manageCourseOccurrence from "./manageCourseOccurrence/manageCourseOccurrence";
import type { State as ManageCourseOccurrenceState } from "./manageCourseOccurrence/manageCourseOccurrence";
import manageCourseOccurrenceInstructors from "./manageCourseOccurrenceInstructors/manageCourseOccurrenceInstructors";
import type { State as ManageCourseOccurrenceInstructorsState } from "./manageCourseOccurrenceInstructors/manageCourseOccurrenceInstructors";
import manageCourseOccurrences from "./manageCourseOccurrences/manageCourseOccurrences";
import type { State as ManageCourseOccurrencesState } from "./manageCourseOccurrences/manageCourseOccurrences";
import manageCoursePromotions from "./manageCoursePromotions/coursePromotionReducer";
import type { State as ManageCoursePromotionsState } from "./manageCoursePromotions/coursePromotionReducer";
import me from "./me/meReducer";
import type { State as MeState } from "./me/meReducer";
import occurrence from "./occurrence/occurrence";
import type { State as OccurrenceState } from "./occurrence/occurrence";
import registrant from "./registrant/registrant";
import type { State as RegistrantState } from "./registrant/registrant";
import registrants from "./registrants/registrants";
import type { State as RegistrantsState } from "./registrants/registrants";
import reviewOccurrence from "./reviewOccurrence/reviewOccurrence";
import type { State as ReviewOccurrenceState } from "./reviewOccurrence/reviewOccurrence";
import stateResources from "./stateResources/stateResources";
import type { State as StateResourcesState } from "./stateResources/stateResources";
import studentDrawer from "./studentDrawer/studentDrawerReducer";
import type { State as StudentDrawerState } from "./studentDrawer/studentDrawerReducer";
import studentRequestCourseOccurrence from "./studentRequestCourseOccurrence/studentRequestCourseOccurrence";
import type { State as StudentRequestCourseOccurrenceState } from "./studentRequestCourseOccurrence/studentRequestCourseOccurrence";
import students from "./students/studentsReducer";
import type { State as StudentsState } from "./students/studentsReducer";
import submitOccurrenceResults from "./submitOccurrenceResults/submitOccurrenceResults";
import type { State as SubmitOccurrenceResultsState } from "./submitOccurrenceResults/submitOccurrenceResults";
import theme from "./theme/theme";
import type { State as ThemeState } from "./theme/theme";
import toasts from "./toasts";
import type { State as ToastsState } from "./toasts";
import rosterUpload from "./rosterUpload/rosterUpload";
import type { State as BulkRosterUploadState } from "./rosterUpload/rosterUpload";
import retoolEventCheckIn from "./retoolEventCheckIn/retoolEventCheckIn";
import type { State as RetoolEventCheckInState } from "./retoolEventCheckIn/retoolEventCheckIn";

export type State = {
  router: RouterState,
  app: AppState,
  studentDrawer: StudentDrawerState,
  auth: AuthState,
  certifications: CertificationsState,
  connect: ConnectState,
  course: CourseState,
  courseItems: CourseItemsState,
  courseOccurrences: CourseOccurrencesState,
  courseInformationDialog: CourseInformationDialogState,
  courseRegistrationDialog: CourseRegistrationDialogState,
  createCourse: CreateCourseState,
  curricula: CurriculaState,
  editCourse: EditCourseState,
  emailCourse: EmailCourseState,
  forms: FormsState,
  images: ImagesState,
  instructor: InstructorState,
  instructorAccount: InstructorAccountState,
  instructorAgreements: InstructorAgreementsState,
  instructorCourses: InstructorCoursesState,
  instructorCourseOccurrences: InstructorCourseOccurrencesState,
  instructorResources: InstructorResourcesState,
  instructorReviews: InstructorReviewsState,
  manageCourse: ManageCourseState,
  manageCourses: ManageCoursesState,
  manageCourseOccurrence: ManageCourseOccurrenceState,
  manageCourseOccurrenceInstructors: ManageCourseOccurrenceInstructorsState,
  manageCourseOccurrences: ManageCourseOccurrencesState,
  manageCoursePromotions: ManageCoursePromotionsState,
  me: MeState,
  occurrence: OccurrenceState,
  registrant: RegistrantState,
  registrants: RegistrantsState,
  reviewOccurrence: ReviewOccurrenceState,
  stateResources: StateResourcesState,
  studentDrawer: StudentDrawerState,
  studentRequestCourseOccurrence: StudentRequestCourseOccurrenceState,
  students: StudentsState,
  submitOccurrenceResults: SubmitOccurrenceResultsState,
  theme: ThemeState,
  toasts: ToastsState,
  rosterUpload: BulkRosterUploadState,
  retoolEventCheckIn: RetoolEventCheckInState,
};

export default (history: History): mixed =>
  combineReducers({
    router: connectRouter(history),
    app,
    auth,
    certifications,
    connect,
    course,
    courseItems,
    courseOccurrences,
    courseInformationDialog,
    courseRegistrationDialog,
    createCourse,
    curricula,
    editCourse,
    emailCourse,
    form,
    forms,
    images,
    instructor,
    instructorAccount,
    instructorAgreements,
    instructorCourses,
    instructorCourseOccurrences,
    instructorResources,
    instructorReviews,
    manageCourse,
    manageCourses,
    manageCourseOccurrence,
    manageCourseOccurrenceInstructors,
    manageCourseOccurrences,
    manageCoursePromotions,
    me,
    occurrence,
    registrant,
    registrants,
    reviewOccurrence,
    stateResources,
    studentDrawer,
    studentRequestCourseOccurrence,
    students,
    submitOccurrenceResults,
    theme,
    toasts,
    rosterUpload,
    retoolEventCheckIn,
  });
