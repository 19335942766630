// @flow
/* eslint flowtype/require-return-type: 0 */

import { lazy } from "react";
import retry from "shared/utilities/retry";

type RouteI = {
  path: string,
  LazyComponent: {},
};

type RoutesI = Array<RouteI>;

export const sharedRoutes: RoutesI = [
  {
    path: "/",
    LazyComponent: lazy(() => retry(() => import("./containers/Home/Home"))),
  },
  {
    path: "/class/:id",
    LazyComponent: lazy(() =>
      retry(() => import("./shared/components/Loader"))
    ),
  },
  {
    path: "/class/:slug/:id",
    LazyComponent: lazy(() =>
      retry(() => import("./containers/Course/Course"))
    ),
  },
  {
    path: "/classes/:state",
    LazyComponent: lazy(() => retry(() => import("./containers/State/State"))),
  },
  {
    path: "/instructor/:id",
    LazyComponent: lazy(() =>
      retry(() => import("./containers/Instructor/Instructor"))
    ),
  },
  {
    path: "/instructor-search",
    LazyComponent: lazy(() =>
      retry(() => import("./containers/InstructorSearch/InstructorSearch"))
    ),
  },
  {
    path: "/legacy-instructor-login",
    LazyComponent: lazy(() =>
      retry(() =>
        import("./containers/LegacyInstructorLogin/LegacyInstructorLogin")
      )
    ),
  },
  {
    path: "/not-found",
    LazyComponent: lazy(() =>
      retry(() => import("./containers/NotFound/NotFound"))
    ),
  },
  {
    path: "/review-class",
    LazyComponent: lazy(() =>
      retry(() => import("./containers/ReviewOccurrence/ReviewOccurrence"))
    ),
  },
  {
    path: "/search-by",
    LazyComponent: lazy(() =>
      retry(() => import("./shared/components/Loader"))
    ),
  },
];

export const instructorRoutes: RoutesI = [
  {
    path: "/accept-agreements",
    LazyComponent: lazy(() =>
      retry(() =>
        import("./containers/InstructorAgreementsForm/InstructorAgreementsForm")
      )
    ),
  },
  {
    path: "/certifications",
    LazyComponent: lazy(() =>
      retry(() => import("./containers/Certifications/Certifications"))
    ),
  },
  {
    path: "/create-course",
    LazyComponent: lazy(() =>
      retry(() => import("./containers/CreateCourse/CreateCourse"))
    ),
  },
  {
    path: "/dashboard/instructor-resources",
    LazyComponent: lazy(() =>
      retry(() =>
        import(
          "./containers/InstructorResourcesLandingPage/InstructorResourcesLandingPage"
        )
      )
    ),
  },
  {
    path: "/dashboard/instructor-resources/:type",
    LazyComponent: lazy(() =>
      retry(() =>
        import("./containers/InstructorResources/InstructorResources")
      )
    ),
  },
  {
    path: "/dashboard/reviews",
    LazyComponent: lazy(() =>
      retry(() => import("./containers/Reviews/Reviews"))
    ),
  },
  {
    path: "/edit-course/:id",
    LazyComponent: lazy(() =>
      retry(() => import("./containers/CreateCourse/CreateCourse"))
    ),
  },
  {
    path: "/instructor-account",
    LazyComponent: lazy(() =>
      retry(() => import("./containers/InstructorAccount/InstructorAccount"))
    ),
  },
  {
    path: "/manage-course/:id",
    LazyComponent: lazy(() =>
      retry(() => import("./containers/ManageCourse/ManageCourse"))
    ),
  },
  {
    path: "/my-classes",
    LazyComponent: lazy(() =>
      retry(() =>
        import(
          "./containers/InstructorCourseOccurrences/InstructorCourseOccurrences"
        )
      )
    ),
  },
  {
    path: "/my-cabelas-classes",
    LazyComponent: lazy(() =>
      retry(() =>
        import("./containers/InstructorCabelasClasses/InstructorCabelasClasses")
      )
    ),
  },
  {
    path: "/my-courses",
    LazyComponent: lazy(() =>
      retry(() => import("./containers/ManageCourses/ManageCourses"))
    ),
  },
  {
    path: "/payments",
    LazyComponent: lazy(() =>
      retry(() => import("./containers/Payments/Payments"))
    ),
  },
  {
    path: "/roster/:id",
    LazyComponent: lazy(() =>
      retry(() => import("./containers/Roster/Roster"))
    ),
  },
  {
    path: "/submit-results/:id",
    LazyComponent: lazy(() =>
      retry(() =>
        import("./containers/SubmitOccurrenceResults/SubmitOccurrenceResults")
      )
    ),
  },
  {
    path: "/students",
    LazyComponent: lazy(() =>
      retry(() => import("./containers/Students/Students"))
    ),
  },
];

export const authedRoutes: RoutesI = [];
